import React from "react";
import Spinner from "react-bootstrap/Spinner";
import "./Filter.css";
import Tags from "../Tags/Tags";
import EnhancedTable from "../Tables/EnhancedTable";
import { Row, Col } from 'react-bootstrap';
import { getFiles, downloadFiles, emptyFileNames, deleteFiles } from './Filter.actions';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as Constants from "../Constants";
import appConfig from '../../config/app-config.json';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ValueTags from "../ValueTags/ValueTags";
import Axios from "axios";
import { Modal, Box } from "@material-ui/core";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import PreViewer from "../PreViewer/PreViewer";

const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vw',
  height: '95vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

class Filter extends React.Component {
  state = {
    tech: [],
    dataPersonal: "",
    intialValue: [],
    noTyped: true,
    arrPills: [],
    valPills: [],
    urlRefresh: "",
    modifyMode: false,
    docs: [],
    loading: false
  };  

  handleDocLoad = () => {
    this.setState({ loading:!this.loading });
  };

  
  // Handler TT's state from props
  handleTT = (param) => {
    // Recover previous Tags and arrays of Values&Ref from pills
    let { tech, arrPills, valPills } = this.state;
    // Comparare them with Actual Tags(param), to obtain the one who takes off
    let results = tech.filter(el => !param.includes(el));
    // If the removed Tag is a Pill, show it
    let index=-1;
    results.forEach(element => {
      for (index=0; index<valPills.length; index++) 
          // eslint-disable-next-line
          if (element.toUpperCase().trim() == valPills[index].toUpperCase().trim()) break;
      if ((index>=0) && (index<valPills.length))
        this.refs["pills"].refs[arrPills[index]].style.opacity = 1;
      }
    );
    // Message addTags
    if ((tech.length === 0) && (param.length > 0))
        document.getElementById("tech").placeholder = "";
    if ((tech.length > 0) && (param.length === 0))
      document.getElementById("tech").placeholder = Constants.addTags;
    this.setState({
      tech: param,
      noTyped: true,
    });
  };

  handleDownload = (param) => {
    if (param.length > 0) {
      let stringParams = "?loggedEid=" + this.props.session.user.eid + "&";
      param.forEach(file => {
        stringParams =
          stringParams +
          "idFiles=" +
          file +
          "&";
      });

      stringParams = stringParams.substring(0, stringParams.length - 1);
      const appConfigEnt = appConfig[this.props.session.environment];

      const url = `${appConfigEnt.apiUri}/inventory` + stringParams;

      // If There is only one file, DO NOT DO A ZIP!!!
      let doZip = (param.length === 1) ? false : true;
      
      this.props.downloadFiles(url, this.props.session.user.apK, doZip);

    } else {
      // This condition is NEVER met ...
      this.notify("error", "There is not selected files");
    }
  };

  handleUpdate = (param) => {
    const modifyMode = true;
    this.setState({
      modifyMode: modifyMode,
    });
    // Send the object to modify in session
    let findIndex = -1, selectedIndex = -1 ;
    this.props.filenames.forEach(element => {
      findIndex++; 
      if (selectedIndex<0) selectedIndex = (element.idFile === param[0]) ? findIndex : -1; 
    });
    this.props.session.modifyMode = modifyMode;
    this.props.session.modifyElement = this.props.filenames[selectedIndex];
  };

  handleDeletes = (param) => {
    if (param.length > 0) {
      let stringParams = "?loggedEid=" + this.props.session.user.eid + "&";
      param.forEach(file => {
        stringParams =
          stringParams +
          "idFiles=" +
          file +
          "&";
      });

      stringParams = stringParams.substring(0, stringParams.length - 1);
      const appConfigEnt = appConfig[this.props.session.environment];

      const url = `${appConfigEnt.apiUri}/inventory` + stringParams;

      const urlRefresh=this.state.urlRefresh.toString();
      
      this.props.deleteFiles(url, this.props.session.user.apK, urlRefresh);

    } else {
      // This condition is NEVER met ...
      this.notify("error", "There are not selected files");
    }
  };
 
  handlePreview = (param) => {
    //Se obtiene idFile de param
    const idFile = param[0];

    //Se obtiene fileType del idFile
    const fileType= param[0].split('.').pop();
    //console.log(fileType);

    if (idFile.length > 0) {
      this.handleDocLoad();
      let stringParams = "?loggedEid=" + this.props.session.user.eid + "&";
      stringParams += "idFiles=" + idFile;
      const appConfigEnt = appConfig[this.props.session.environment];

      const url = `${appConfigEnt.apiUri}/inventory` + stringParams;
      // Retrieve URL file to preview
      Axios.get(url, {
        method: 'GET',
        responseType: 'json',
          headers: {
            Authorization: this.props.session.user.access_token,
            'Content-Type': 'application/json',
            'x-api-key': this.props.session.user.apK
          },          
      },
      ).then(fileToPreview => {
        // Set docs for DocViewer
        this.setState({
          docs: [{ 
            uri: fileToPreview.data[0].downloadUrl, 
            'fileType': fileType, 
            fileName: fileToPreview.data[0].idFile,
            isLoading: true,
          }],
          });
    
      }).catch(err => { 
        console.log(err);
        this.notify("error", "Something was wrong. Please, try again!");
      });
  
    } else {
      // This condition is NEVER met ...
      this.notify("error", "There is not selected file");
    }
  };

  validate = () => {
    
    const techElement = document.getElementById("tech");    

    // Divide the allFreeText into freeText and filetype
    let fullText = techElement.value;
    let textInput = fullText;
    let typeFile = '';
    let placeTypeFile = textInput.indexOf("filetype:");
    if (placeTypeFile !== -1) {
      if (placeTypeFile !== 0) {
        textInput = fullText.substring(0, placeTypeFile).trim();
        typeFile = fullText.substring(placeTypeFile + "filetype:".length).trim();
      } else {
        typeFile = fullText.substring("filetype:".length, "filetype:".length + 4);
        textInput = fullText.substring(placeTypeFile + "filetype:".length + 4).trim();
      }
      console.log(textInput);
    }

    var validated = false;
    //let textTech = techElement && techElement.value !== null && techElement.value !== undefined ? techElement.value : "Campo vacío";
    let textTech = techElement.value.trim();
    textTech = textTech.toLowerCase();
    const buscaCadena = "filetype:"
    //Validation1: Finding more than one "filetype:" into the input text
    const fileType2 = textTech.split(buscaCadena).length - 1;
    
    //Validation2: Checking the correct position of keyword "filetype: "
    let arrayTextTech = [ ]    
    arrayTextTech = textTech.split(" ");
    const positionFiletype  = arrayTextTech.findIndex(elemento => elemento.includes("filetype:"));
        
    if (fileType2 >=2) {
      this.notify("error", Constants.doubleFileType);
    //techElement.classList.add("error")
    } else if (positionFiletype !==-1 && positionFiletype !== 0 && positionFiletype !== arrayTextTech.length-1 ){
      if (arrayTextTech[positionFiletype-1] !== ' ' && arrayTextTech[positionFiletype+1] !== ' ') {
        this.notify("error", Constants.textAfteFileType);        
        validated = false;
      }
    //Validation3: Not spaces between "filetype:" and extension type "pdf, pptx, docx"        
    } else if (/^\s/.test(typeFile)){
      this.notify("error", Constants.spacesFiletype);      
      validated = false;     
    }
    
    else {
      validated = true;      
    }
    validated === true ? techElement.classList.remove("error"):techElement.classList.add("error")
    return validated;  
  };  

  updateFilenameList = () => {
	if (!this.validate()) return;
    let { dataPersonal, tech } = this.state;
    let restore = false;
    let allFreeText = document.getElementById("tech").value;
    // Search for ALL TAGS
    if (tech.length === 0) {
      if (document.getElementById("tech").value === "") this.notify("warn", Constants.tagsEmpty);
      // Add ALL to tech
      tech=['ALL'];
      restore = true;
    }
    // Divide the allFreeText into freeText and filetype
    let freeText = allFreeText;
    let filetype = '';
    let indexFiletype = allFreeText.indexOf("filetype:");
    if (indexFiletype !== -1) {
      if (indexFiletype !== 0) {
        freeText = allFreeText.substring(0, indexFiletype).trim();
        filetype = allFreeText.substring(indexFiletype + "filetype:".length).trim();
      } else {
        filetype = allFreeText.substring("filetype:".length, "filetype:".length + 4).trim();
        freeText = allFreeText.substring(indexFiletype + "filetype:".length + 4).trim();
      }      
    }

    if (filetype ==="doc") {
      console.log(filetype);
      filetype = "docx";
    }else if (filetype ==="ppt"){
      console.log(filetype);
      filetype = "pptx";
    }

    let stringParams = "?";
    stringParams+="loggedEid=" + this.props.session.user.eid + "&status=APPROVED";
    if (dataPersonal!=="") stringParams+="&personalDataIncluded="+dataPersonal;
    if (tech!=="") tech.forEach(o => { stringParams+="&keywords="+o; });
    stringParams+="&freeText="+freeText;
    stringParams+="&filetype="+filetype;
    const appConfigEnt = appConfig[this.props.session.environment];

    const url = `${appConfigEnt.apiUri}/inventory/list` + stringParams;
    this.props.getFiles(url, this.props.session.user.apK);
    // Restore tech
    if (restore) tech=[];
    // Refresh Table
    this.setState({
      urlRefresh: url,
      });
  };

  setOptions = options => {
    let list = [];
    options.forEach(o => {
      list.push(<option key={o}>{o}</option>);
    });
    return list;
  };
  
  // Handler Chips
  handleDelete = (param) => {
    let { tech, intialValue, noTyped } = this.state;
    if (noTyped) {
      intialValue.push(param);
      this.setState({ intialValue });
      }
    // Message addTags
    if ((tech.length === 0) && (param.length > 0))
        document.getElementById("tech").placeholder = "";
    if ((tech.length > 0) && (param.length === 0))
      document.getElementById("tech").placeholder = Constants.addTags;
    tech.push(param);
    this.setState({ tech });
    
  };

  //Toasts
  notify = (type, msg) => toast[type]((type === "error" ? "" : " ") + msg);

  componentDidUpdate () {
    const {modifyMode} = this.state;
    // Modify implies a redirect
    if (!modifyMode) {
      // Height of input Tags
      let heightInput = (document.getElementById("tech")) ? 
      document.getElementById("tech").parentNode.offsetHeight :
      1;

      document.getElementById("spaceTags").height=heightInput;
    }
  }

  componentDidMount () {
    // Create an Array (value,pills)
    let pills = this.refs["pills"].refs;
    let arrPillsAux = [];
    let valPillsAux = [];
    for (let prop in pills) {
      arrPillsAux.push(prop);
      valPillsAux.push(this.refs["pills"].refs[prop].innerText.toUpperCase());
    };
    this.setState({
      arrPills: arrPillsAux,
      valPills: valPillsAux,
    });
    // 25/08/2020 - FIX 4 - juan.d.martin.garcia
    // After a search with files in Download window, 
    // we change to another one and then we return to Download window again. 
    // Result table is filled with the previous search. We must set empty de array.
    if (null != this.props.filenames && typeof this.props.filenames != 'undefined' && this.props.filenames.length > 0) {
      this.props.emptyFileNames();
    }

    }

  render() {
    const {session, filenames, loadingTable, message } = this.props;
    const {intialValue, modifyMode, docs, loading} = this.state;
    
    // Messages
    if (typeof message.type !== 'undefined') {
      this.notify(message.type, message.msg);
    }

    // Modify redirect to UPLOAD
    if (modifyMode) {
      this.props.session.modifyMode = modifyMode;
      return <Redirect to="/UPLOAD" />
    }

    
    let openPrev = (docs.length>0) ? true : false; 
     
    const handleClose = () => {
      this.setState({
      docs: [],
      loading: false       
      });      
    };

    return (     
      <div id="filter" className="Filter">
        <div id="preview">
        {loading && (
            <div className="SeparatorV" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1, backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
               <Spinner animation="border" style={{color: "white"}}></Spinner>                                      
            </div>
          )}          
          <Modal
            open={openPrev}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              {((docs.length > 0) && (
                (docs[0].fileType==="doc")
                  || (docs[0].fileType==="docx")
                  || (docs[0].fileType==="dotx")
                  || (docs[0].fileType==="ppt")
                  || (docs[0].fileType==="pptx")
                 )) ? (
                  <PreViewer 
                    src={docs[0].uri}
                  />            
                ) : (
                  <DocViewer
                    pluginRenderers={ DocViewerRenderers }
                    documents={ docs }
                    prefetchMethod="GET"
                    onLoadSuccess={this.handleDocLoad}
                  />                
              )}
            </Box>            
          </Modal>          
        </div>
        
        <div>
          <img src="/images/pixelt.png" alt="" width="1" height="25" border="0"/>
        </div>
        <Row>
          <Col xs={1}>&nbsp;</Col>
          <Col xs={10} className="w-90 px-0 mx-auto d-inline-flex">
            {/*Download recovers Tags from Session, not from tags.txt*/}
            <Tags
              id="tech"
              className="root"
              freeSolo={true}
              changeHandler={this.handleTT}
              tags={session.listActualTags}
              values={intialValue}
              placeholder={Constants.addTags}
            />
            <button id="boton" onClick={this.updateFilenameList}>
              <img src="/images/lens.svg" alt="Search"/>
            </button>
            <div>
              <img id="spaceTags" src="/images/pixelt.png" alt="" width="1" height="1" border="0"/>
            </div>
          </Col>
          <Col xs={1}>&nbsp;</Col>
        </Row>
        <div className="SeparatorV"></div>
        <Row>
          <Col xs={1}>&nbsp;</Col>
          <Col xs={10} className="px-0 mx-0">
            <ValueTags 
              ref="pills" 
              pills={session.listTopPills} 
              handleDelete={this.handleDelete}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={1}>&nbsp;</Col>
          <Col xs={10} className="px-0 mx-0">
            {(!loadingTable) ? (
                ((null != filenames) && (typeof filenames != 'undefined')) ? (
                  (filenames.length > 0) ? (
                    <EnhancedTable 
                      filenames={filenames}
                      handleDownloads={this.handleDownload}
                      handleUpdate={this.handleUpdate}
                      handleDeletes={this.handleDeletes}
                      handlePreview={this.handlePreview}
                      role={session.user.role}
                    />
                    ) : (
                      <div className="White">&nbsp;{Constants.resultsNotFound}</div>
                    )
                ) : (
                  <div></div>
                )
              ) : (
                <div className="SeparatorV"><Spinner animation="border" style={{color: "white"}}></Spinner></div>
              )}
          </Col>
          <Col xs={1}>&nbsp;</Col>
        </Row>
      </div>
    );
  }
}
const mapDispatchToProps = {
  getFiles,
  downloadFiles,
  deleteFiles,
  emptyFileNames
}

const mapStateToProps = state => {
  return { session: state.session,
          filenames: state.download.fileNames, 
          loadingTable: state.download.loadingTable,
          message: state.download.message,
        }
}
export default connect(mapStateToProps, mapDispatchToProps)(Filter);