import "./Tags.css";
import React from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Spinner from "react-bootstrap/Spinner";
import { List, ListItem } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#FFFFFF',
        outline: 'thin solid #A100FF',
        //border: 'thin solid #A100FF',
        margin: '0px',
        'font-family': 'Graphik Web',
        'font-weight': 400,
        color: '#444',
        'line-height': 1.3,
        width: '100%',
        //height: '32px',
        '& > * + *': {
          marginTop: theme.spacing(3),
        },
        '& .MuiInputBase-input': {
          'font-family': 'Graphik Web',
          'font-size': '0.85rem',
          color: '#444', // Text color
        },
        '& .MuiInput-underline:before': {
          //border: 'thin solid #A100FF',
          height: '32px',
        },
        '& .MuiInput-underline:hover:before': {
          //border: 'thin solid #A100FF',
        },
        '& .MuiInput-underline:after': {
          //border: 'thin solid #A100FF',
        },
    },
    inputRoot: {
      //'z-index': 1,
      backgroundColor: '#FFFFFF',
      border: 'thin solid #A100FF',
      'font-weight': 400,
      margin: '0px',
      //height: '32px',
      padding: '0px',
      "&:first-child": {
        paddingTop: 1,
        paddingLeft: 4
      }
    },
    option: {
      backgroundColor: '#FFFFFF',
      'font-family': 'Graphik Web',
      'line-height': 1.3,
      color: '#444',
      margin: '0px',
      height: '32px',
      padding: '8px',
    },
    rerror: {
      'background-color': 'rgba(204,41,41,0.15)',
      width: '100%',
  },
    irerror: {
      border: '2px solid #cc2929',
      'background-color': 'rgba(204,41,41,0.15)',
  },

}));

const StyleChip = withStyles({
  root: {
    'border-radius': '0px',
    'font-family': 'Graphik Web',
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    backgroundColor: '#F7F5F5',
  }
})(Chip);

var Terms = [];
var enableFreeSolo = false;

// Autocomplete Object
const SampleFab = (props) => {
  const classes = useStyles();
  const claseRootActual = (props.className === "error") ? classes.rerror : classes.root;
  const claseInputRootActual = (props.className === "error") ? classes.irerror : classes.inputRoot;
  if (Terms.length === 0) {
    return <Spinner animation="border" style={{color: "white"}}></Spinner>;
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const ListboxComponent = React.forwardRef(function ListboxComponent(
    props,
    ref
  ) {
    const { children, role, ...other } = props;
    
     return (
      <div ref={ref}>
        <div {...other}>
          <List>
            <ListItem dense={true} disabled>SUGGESTED SEARCHES</ListItem>
              {children}
          </List>
        </div>
      </div>
    );  
  });

  return  <Autocomplete
    multiple
    freeSolo={enableFreeSolo}
    className={claseRootActual}    
    id={props.id}
    classes={{
        inputRoot: claseInputRootActual,
        option: classes.option,
    }}
    ListboxComponent={ListboxComponent}
    options={Terms.map(option => option.title)}
    defaultValue={props.values}
    renderTags={(value: string[], getTagProps) =>
      value.map((option: string, index: number) => (
        <StyleChip 
          variant="default" 
          size="small" 
          label={option} {...getTagProps({ index })} />
        ))
    }
    renderInput={params => (
      <TextField 
        {...params} 
        margin="none"
        placeholder={props.placeholder}
        onKeyDown={handleKeyDown}
      />
    )}
    onChange={(event, value) => props.changeHandler(value)}
    />;
}

export default class Tags extends React.Component {
  constructor(props) {
    super(props);
      Terms = [];

      let lines = this.props.tags;
      if (lines !== undefined) {
        lines.forEach(line => {
          Terms.push({ title: line});
        });
      }

      enableFreeSolo=this.props.freeSolo;

      this.state = {
        tags: Terms,
       };
    this.changeHandler = this.props.changeHandler.bind(this);
}

componentDidUpdate() {
  var pathname = window.location.pathname.toUpperCase();
  if ((pathname === '/DOWNLOAD') && (null != document.getElementById("tech"))) {
    document.getElementById("tech").focus();
  }
}

componentDidMount() {
  var pathname = window.location.pathname.toUpperCase();
  if ((pathname === 'DOWNLOAD') && (null != document.getElementById("tech"))) {
    document.getElementById("tech").focus();
  }
  if (null != this.props.loadHandler && typeof this.props.loadHandler != 'undefined')
   this.props.loadHandler();
}

render() {
  return (
      <SampleFab 
        id={this.props.id} 
        className={this.props.className} 
        changeHandler={this.changeHandler} 
        values={this.props.values}
        placeholder={this.props.placeholder}
      />
    );
  }
}